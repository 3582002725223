.header-area {
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.dropdown1 {
    position: relative;
  }
  
  .dropdown-menu2 {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block; /* Maintain block but hide it using opacity */
    visibility: hidden; /* Better alternative to 'display: none' for transitions */
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background: linear-gradient(to right, #4834d4, #341f97);
    /* background-color: #fff; */
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .dropdown1.open .dropdown-menu2 {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  
.classy-navbar-toggler .navbarToggler span{
    background-color: #f2f4f8 !important;
}

.header-area.sticky {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: #0d003b;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    border-bottom: none;
}

.dark.classy-nav-container {
    background-color: transparent;
}

.classy-navbar {
    height: 90px;
    padding: 0;
}

.classynav ul li a {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
}

.classynav ul li a:hover{
    color: #fff;
}

.classynav ul li .megamenu li a {
    font-size: 80%;
}

.btn:not(:disabled):not(.disabled){
    cursor: pointer;
    color: #fff;
    border: 2px solid #7450fe;
    border-radius: 100px;
}

#nav{
    margin-bottom: 0;
    width: 100%;
}

.classy-nav-container{
    background-color: transparent !important ;
    transition: all .7s;
    padding: 10px 0;
}

.classy-nav-container.sticky{
    background-color: #0d003b !important;
}

@media(max-width: 991px) {
    #nav{
        background-color: #192a56 !important;
    }
    .classy-navbar .classy-menu{
        background-color: #192a56 !important;
    }
    .classynav>ul>li>a{
        background-color: transparent !important;
        border-bottom: 1px solid rgb(93 87 87 / 50%);
    }

    .breakpoint-off .classynav{
        flex-direction: column !important;
    }

    .classycloseIcon .cross-wrap span.top,
    .classycloseIcon .cross-wrap span.bottom{
        background-color: #fff !important;
    }

    .classy-menu .login-btn{
        margin-right: 150px ;
    }

    .classynav>ul>li>a{
        border-bottom: 1px solid rgba(255,255,255,.05) !important;
    }
}

.nav-brand img {
    margin-top: 1px;
    margin-left: 1px;
    width: 60px;
}


